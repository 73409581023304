import dayjs from 'dayjs'
import Table from './Table'
import ContactTag from './ContactTag'
import { commonStyle } from './style'
import HotelAvatar from './HotelAvatar'
import { Text, View } from '@react-pdf/renderer'
import { RoomWithRelation } from '../../../models/Room'
import { currencyFormat } from '@supplement-bacon/alela-uikit'

interface Props {
  data: RoomWithRelation[]
}

const RoomTable = ({ data }: Props) => {
  const RoomCols = [
    {
      title: 'Hotel',
      key: 'hotel',
      render: (record: RoomWithRelation) => (
        <HotelAvatar hotel={record.hotel} />
      ),
    },
    {
      title: 'Dates',
      key: 'dates',
      render: (record: RoomWithRelation) =>
        record.from && record.to ? (
          <View>
            <Text>
              {dayjs(record.from).format('DD/MM (HH:mm)')} -
              {dayjs(record.to).format('DD/MM (HH:mm)')}
            </Text>
            <View style={[commonStyle.tag]}>
              <Text>
                {Math.round(dayjs(record.to).diff(record.from, 'day', true))}{' '}
                nights
              </Text>
            </View>
          </View>
        ) : (
          <View style={[commonStyle.tag]}>
            <Text>x</Text>
          </View>
        ),
    },
    {
      title: 'Reference',
      key: 'reference',
    },
    {
      title: 'Room type',
      key: 'category',
    },
    {
      title: 'Room credits',
      key: 'roomCredits',
      render: (record: RoomWithRelation) => (
        <Text>
          {currencyFormat(Number(record.roomCredits), record.currency)}
        </Text>
      ),
    },
    {
      title: 'Contacts',
      key: 'contacts',
      render: (record: RoomWithRelation) => (
        <View style={commonStyle.spaceHorizontal}>
          {record.contacts.map((contact) => (
            <ContactTag key={contact.id} contact={contact} />
          ))}
        </View>
      ),
    },
  ]

  return <Table columns={RoomCols} data={data} />
}

export default RoomTable
