import Footer from './components/Footer'
import RoomTable from './components/RoomTable'
import TimeTable from './components/TimeTable'
import { commonStyle } from './components/style'
import TravelTable from './components/TravelTable'
import GroundTable from './components/GroundTable'
import ContactTable from './components/ContactTable'
import { RoadsheetData } from '../../models/Roadsheet'
import { addressFormatter } from '../../utils/formatters'
import { Page, Text, View, Document, Image } from '@react-pdf/renderer'
import dayjs from 'dayjs'

interface Props {
  data: RoadsheetData
}

const Roadsheet = ({ data }: Props) => {
  return (
    <Document>
      <Page size="A4" wrap style={commonStyle.section}>
        <View style={commonStyle.container}>
          <View style={commonStyle.row} wrap={false}>
            <View style={commonStyle.col12}>
              <View style={[commonStyle.spaceHorizontal, { gap: 10 }]}>
                {data.bookings[0].artist.spotify_image && (
                  <Image
                    src={data.bookings[0].artist.spotify_image}
                    style={{ height: 55, width: 55, borderRadius: 10 }}
                  />
                )}
                <View style={[commonStyle.spaceVertical, { gap: 5 }]}>
                  <Text style={commonStyle.h1}>
                    {data.bookings[0].artist.name}
                  </Text>
                  <Text style={commonStyle.h3}>Event: {data.event.name}</Text>
                  <Text style={commonStyle.h3}>
                    Date: {dayjs(data.event.start_date).format('DD/MM/YYYY')}
                  </Text>
                  {data.event.venues &&
                    data.event.venues.map((venue) => (
                      <View key={venue.id} style={commonStyle.spaceHorizontal}>
                        <Image
                          key={venue.id}
                          src={{
                            uri: venue.logo ?? '',
                            method: 'GET',
                            headers: { 'Cache-Control': 'no-cache' },
                            body: '',
                          }}
                          style={{ height: 25, width: 25, borderRadius: 2 }}
                        />
                        <View
                          key={venue.id}
                          style={[commonStyle.spaceVertical, { gap: 3 }]}
                        >
                          <Text style={commonStyle.p}>{venue.name}</Text>
                          <Text style={commonStyle.muted}>
                            {addressFormatter(venue.address)}
                          </Text>
                        </View>
                      </View>
                    ))}
                </View>
              </View>
            </View>
          </View>
          {(data.event.contacts ?? []).length > 0 && (
            <View
              style={commonStyle.row}
              wrap={data.event.contacts && data.event.contacts.length > 10}
            >
              <View style={commonStyle.col12}>
                <Text style={commonStyle.h2}>Venue contacts</Text>
              </View>
              <View style={commonStyle.col12}>
                <ContactTable data={data.event.contacts ?? []} />
              </View>
            </View>
          )}

          {(data.bookings[0].contacts ?? []).length > 0 && (
            <View
              style={commonStyle.row}
              wrap={data.bookings[0].contacts.length > 10}
            >
              <View style={commonStyle.col12}>
                <Text style={commonStyle.h2}>Artist contacts</Text>
              </View>
              <View style={commonStyle.col12}>
                <ContactTable data={data.bookings[0].contacts ?? []} />
              </View>
            </View>
          )}

          {(data.event.performances ?? []).length > 0 && (
            <View
              style={commonStyle.row}
              wrap={data.bookings[0].stages.length > 1}
            >
              <View style={commonStyle.col12}>
                <Text style={commonStyle.h2}>Time Table</Text>
              </View>
              <TimeTable data={data.event.performances ?? []} />
            </View>
          )}

          {(data.bookings[0].travels ?? []).length > 0 && (
            <View style={commonStyle.row} wrap={true}>
              <View style={commonStyle.col12}>
                <Text style={commonStyle.h2}>Travels</Text>
              </View>
              <View style={commonStyle.col12}>
                <TravelTable data={data.bookings[0].travels ?? []} />
              </View>
            </View>
          )}

          {(data.bookings[0].rooms ?? []).length > 0 && (
            <View style={commonStyle.row} wrap={true}>
              <View style={commonStyle.col12}>
                <Text style={commonStyle.h2}>Hotels</Text>
              </View>
              <View style={commonStyle.col12}>
                <RoomTable data={data.bookings[0].rooms ?? []} />
              </View>
            </View>
          )}

          {(data.bookings[0].grounds ?? []).length > 0 && (
            <View style={commonStyle.row} wrap={true}>
              <View style={commonStyle.col12}>
                <Text style={commonStyle.h2}>Ground</Text>
              </View>
              <View style={commonStyle.col12}>
                <GroundTable data={data.bookings[0].grounds ?? []} />
              </View>
            </View>
          )}
        </View>

        {/* FOOTER */}
        <Footer />
      </Page>
    </Document>
  )
}

export default Roadsheet
